import React from "react";
import ReactDOM from "react-dom";
import { Grid, CircularProgress, useTheme } from "@mui/material";

export default function FullPageLoader() {
    const theme = useTheme();

    return ReactDOM.createPortal(
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
                top: 0,
                left: 0,
                backgroundColor: "rgba(166, 166, 166, 0.7)", // Transparent overlay
                zIndex: theme.zIndex.modal + 1000, // Ensure it's above modals
            }}
        >
            <CircularProgress color="secondary" />
        </Grid>,
        document.body // Render this loader at the root of the DOM
    );
}
