import React from "react";
import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";

import AlertBanner from "components/common/AlertBanner/AlertBanner";
import Banner from "components/layout/Banner";
import { IS_ONBOARDING } from "redux/actionTypes/actionTypes";
import RestrictedFeaturePopup from "components/common/Popup/RestrictedFeaturePopup";
import UpgradeToPremiumPopup from "components/common/Popup/UpgradeToPremiumPopup";
import CheckPermissionFunction from "services/CheckPermissionFunction";

import TopBar from "./TopBar";
import Nav from "./Nav";
import { fetchDatasourceList } from "redux/actions/dataSourcesActions";
import { checkForFeature } from "services/featureFlagHOC";
import { fetchReverseConnectorsList } from "redux/actions/reverseConnectorsActions";
import MobileNav from "./Nav/MobileNav";
import useApi from "hooks/useApi";
const useStyles = makeStyles((theme) => ({
    root: {
        //  added flex and flex direction
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    withoutHeader: {
        minHeight: "100vh",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        backgroundColor: "#FAFAFA",
    },
    container: {
        display: "flex",
        height: "100vh",
        backgroundColor: "#FAFAFA",
    },
    pageContainer: {
        backgroundColor: "#FAFAFA",
    },
}));

function FallBack() {
    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh", width: "100vw" }}>
            <CircularProgress />
        </Grid>
    );
}

export default function Main(props) {
    const classes = useStyles();
    const user = useSelector((state) => state.users);
    const warehouses = useSelector((state) => state.dataWareHouses.dataWareHouses);
    const [banners, setBanners] = React.useState([]);
    const viewDataSourcePermission = CheckPermissionFunction(["report.view"]);
    const viewReverseConnectorPermission = CheckPermissionFunction(["sync.view"]);
    const [collections, setCollections] = React.useState(null);
    const [, collectionsIsLoading, getCollections] = useApi();
    const fetchCollections = async () => {
        const res = await getCollections({
            module: "askDc",
            method: "getCollections",
            returnResult: true,
        });
        setCollections(res);
    };

    // const [margin, setMargin] = React.useState(94);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (viewDataSourcePermission) {
            dispatch(fetchDatasourceList());
        }
        if (
            viewReverseConnectorPermission &&
            checkForFeature(user?.user?.subscription_meta_data.features_flags, ["ACTIVE_DESTINATION"])
        ) {
            dispatch(fetchReverseConnectorsList());
        }
        fetchCollections();
    }, []);

    React.useEffect(() => {
        if (user?.user?.plan_name !== "Unlimited" && user?.user?.warehouses?.length === 0 && warehouses?.length === 0 && collections?.length === 0) {
            dispatch({ type: IS_ONBOARDING, payload: true });
        }else{
            dispatch({ type: IS_ONBOARDING, payload: false });
        }
    }, [user?.user, collections]);

    if (collectionsIsLoading) {
        return <Grid container justifyContent="center" alignItems="center" style={{ height: "90vh", width: "100vw" }}><CircularProgress /></Grid>;
    }
    return (
        <>
            <Container maxWidth={false} disableGutters className={classes.pageContainer}>
                <Banner banners={banners} setBanners={setBanners} />
                {screen.width > 768 && <TopBar pageTitle={props.pageTitle} />}
                <UpgradeToPremiumPopup />
                <RestrictedFeaturePopup isEarlyAccessFeature={props.features.includes("ORCHESTRATION")} />
                <div className={classes.container}>
                    {screen.width < 768 ? <MobileNav /> : <Nav />}
                    {/* <OnboardingWizard /> */}
                    <Suspense fallback={<FallBack />}>
                        <div
                            className={clsx({
                                [classes.root]: props.pageTitle !== "homepage",
                                [classes.withoutHeader]: props.pageTitle === "homepage",
                            })}
                            style={{ marginTop: "94px", overflowY: "auto" }}
                        >
                            <props.comp {...props} />
                        </div>
                    </Suspense>
                </div>
            </Container>
            <AlertBanner
                message="You have unpaid invoice(s). Please settle your dues to keep using our services without interruptions."
                redirect_url={user.invoiceData?.invoice_link}
                condition={user.invoiceData?.status === "overdue"}
                buttonText="Pay here"
            />
        </>
    );
}
