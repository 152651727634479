import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const Puller = styled("div")(() => ({
    width: 108,
    height: 6,
    backgroundColor: "#1D1B20",
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 54px)",
}));

function SwipeableEdgeDrawer({ children, heading, toggleDrawer, open, height, puller }) {
    return (
        <div>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
                PaperProps={{
                    style: {
                        height: height ? height : "70%",
                        overflow: "scroll",
                    },
                }}
            >
                <div
                    style={{
                        position: "sticky",
                        top: 0,
                        right: 0,
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "inherit",
                    }}
                >
                    {puller && <Puller />}
                    <Typography
                        sx={{
                            padding: 3,
                            textAlign: "center",
                            fontWeight: 600,
                        }}
                    >
                        {heading}
                    </Typography>
                </div>
                {children}
            </SwipeableDrawer>
        </div>
    );
}

SwipeableEdgeDrawer.propTypes = {
    window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
