import axios from "config/reverseETLaxios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import ReverseConnectorTransformation from "services/Api/DataTransformations/ReverseConnectorTransformation";
import ForwardAxios from "config/axios";

class UserEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `/reverse-connectors`;
        this.transformer = new ReverseConnectorTransformation();
    }
    getAll() {
        return axios.get(`${this.resourceEndpoint}`);
    }
    getOne(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.slug}`);
    }
    getSyncs(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.slug}/syncs`);
    }
    getOneSync(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.slug}/syncs/${data.apiData.syncId}`);
    }
    getConfiguredSyncs(data) {
        return ForwardAxios.get(`${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs`);
    }
    getOneConfiguredSync(data) {
        return ForwardAxios.get(`${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}`);
    }
    addConfiguredSync(data) {
        return ForwardAxios.post(`${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs`, data.apiData.data);
    }
    runConfiguredSync(data) {
        return ForwardAxios.post(`${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}/run`);
    }
    deleteSync(data) {
        return ForwardAxios.delete(`${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}`);
    }
    changeConfiguredSyncStatus(data) {
        return ForwardAxios.post(
            `${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}/status`,
            data.apiData.data
        );
    }
    editLevels(data) {
        return ForwardAxios.patch(
            `${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}`,
            data.apiData.data
        );
    }
    editConfiguredSync(data) {
        return ForwardAxios.patch(
            `${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}`,
            data.apiData.data
        );
    }
    getConfiguredSyncChangeHistory(data) {
        return axios.get(
            `${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}/change-history`
        );
    }
    patchNotificationLevel(data) {
        return ForwardAxios.patch(
            `${this.resourceEndpoint}/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}/notification-level`,
            {
                notificationLevel: data.apiData.notificationLevel,
            }
        );
    }
    getReverseConnectorDashboardData(data) {
        return axios.get(`/account/reverse_connector_logs${data.apiData.data}`);
    }
    getDynamicFieldsForSync(data) {
        return ForwardAxios.post(
            `reverse-connector/${data.apiData.slug}/syncs/${data.apiData.syncId}/dynamic-fields`,
            data.apiData.data
        );
    }
}

export default UserEndpoints;
