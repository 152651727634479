import React, { useState } from "react";
import { Drawer, IconButton, List, AppBar, Toolbar, Typography, ListItem, ListItemIcon, TextField, Grid } from "@mui/material";
import workspaceIcon from "assets/images/workspace/Workspace icon.svg";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_SIDENAV } from "redux/actionTypes/actionTypes";
import MainNavItem from "./MainNavItem";
import { navData, NavMapper } from "./NavData";
import Dropdown from "./Dropdown";
import CheckPermissions, { checkForPermission } from "services/CheckPermissions";
import { CheckAccountPermissions } from "services/CheckPermissions";
import OnboardingFlagHOC from "components/Onboarding/common/OnboardingFlagHOC";
import { checkForFeature } from "services/featureFlagHOC";
import CheckPermissionFunction from "services/CheckPermissionFunction";
import { IoStar } from "react-icons/io5";
import { getAuth } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SwipeableEdgeDrawer from "components/common/MobileDrawer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useApi from "hooks/useApi";
import Cookies from "js-cookie";
import config from "constants/config";
import FullPageLoader from "components/common/UIComponents/FullPageLoader.js";

const useStyles = makeStyles((theme) => ({
    mobileNav: {
        border: "1px solid #ECECEC",
        borderRadius: "0.5rem",
        margin: "10px",
        marginBottom: "10px",
        padding: 5,
    },
    subDrawer: {
        width: "100%",
        backgroundColor: "#FBFBFB",
        zIndex: 1300,
        padding: theme.spacing(2),
    },
    appBar: {
        backgroundColor: "#fff",
        boxShadow: "none",
        borderBottom: "1px solid #D4DCE4",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
        },
    },
    closeButton: {
        position: "absolute",
        left: theme.spacing(1),
        top: theme.spacing(1),
    },
    navList: {
        marginTop: theme.spacing(8),
        minHeight: 600,
    },
    backButton: {
        marginBottom: theme.spacing(2),
    },
    item: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    listItem: {
        background: "#FFFFFF",
        width: "80%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
        minHeight: "5rem",
        margin: "0",
        marginBottom: "10px",
        padding: "1rem",
        color: "#2C2C2C",
    },
    userAvatar: {
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        height: 24,
        width: 24,
        fontSize: 12,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    selected: {
        background: "#FD95671A",
    },
}));
const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export const MobileSideNavItem = ({
    name,
    subtitle,
    redirectLink,
    userFeatures,
    requiredFeatures,
    requiredPermission,
    account_level_permission,
}) => {
    const classes = useStyles();
    const isFeatureEnabled = checkForFeature(userFeatures, requiredFeatures);
    return (
        <div className={classes.mobileNav}>
            {CheckPermissionFunction(requiredPermission, account_level_permission) ? (
                <Link to={redirectLink} color="primary" underline="none">
                    <div className={classes.listItem}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.1rem",
                            }}
                        >
                            {!isFeatureEnabled && (
                                <span
                                    style={{
                                        position: "relative",
                                        top: "20px",
                                        alignSelf: "flex-end",
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "5px",
                                        backgroundColor: "#FCD6BB",
                                        color: "#FF6A39",
                                        padding: "4px 1px 4px 4px",
                                        width: "17%",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <IoStar
                                        style={{
                                            color: "#FF6A39",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            fontSize: "0.8rem",
                                            fontWeight: 500,
                                        }}
                                    >
                                        Pro
                                    </Typography>
                                </span>
                            )}
                            <span className={classes.item}>
                                <Typography
                                    sx={{
                                        width: "100%",
                                        textAlign: "left",
                                        fontSize: "0.94rem",
                                        fontWeight: 600,
                                    }}
                                >
                                    {name}
                                </Typography>
                            </span>
                            <Typography
                                sx={{
                                    color: "#ACACAC",
                                    width: "100%",
                                    textAlign: "left",
                                    fontSize: "0.7rem",
                                    fontWeight: 500,
                                    lineHeight: "14px",
                                }}
                            >
                                {subtitle}
                            </Typography>
                        </div>
                    </div>
                </Link>
            ) : null}
        </div>
    );
};

const WorkspaceDrawer = ({ open, toggleDrawer }) => {
    const workspaces = useSelector((state) => state.users?.user.workspaces);
    const currentWorkspaceId = useSelector((state) => state.users?.user.current_workspace_id);
    const [filter, setFilter] = useState("");
    const filteredWorkspaces = workspaces.filter((workspace) => workspace.name.toLowerCase().includes(filter));
    const classes = useStyles();
    const [, isLoading, switchWorkspace] = useApi();
    const onWarehouseSwitch = async (workspace_id) => {
        toggleDrawer(false);
        await switchWorkspace({
            module: "workspace",
            method: "switchWorkspace",
            apiData: {
                workspace_id: workspace_id,
            },
        });
        const auth = getAuth();
        const user = auth.currentUser;
        const idToken = await user.getIdToken(true);
        Cookies.set("accessToken", idToken);
        Cookies.set("idToken", idToken);
        window.location.href = config.frontendUrl;
    };

    return (
        <>
            {" "}
            {isLoading ? (
                <FullPageLoader />
            ) : (
                <SwipeableEdgeDrawer open={open} toggleDrawer={toggleDrawer} heading={"Select the Workspace"} puller={false}>
                    <Grid container alignItems="center" justifyContent={"center"}>
                        <TextField
                            type="search"
                            placeholder="Search for Workspaces"
                            fullWidth
                            style={{ width: "90%" }}
                            onChange={(e) => {
                                setFilter(e?.target?.value?.toLowerCase());
                            }}
                        />
                        <List style={{ width: "90%", overflowY: "auto" }}>
                            {filteredWorkspaces.map((workspace) => {
                                const isSelected = workspace.workspace_id === currentWorkspaceId;
                                return (
                                    <ListItem onClick={() => onWarehouseSwitch(workspace.workspace_id)}>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{ padding: 8 }}
                                            className={isSelected ? classes.selected : null}
                                        >
                                            <Grid item>
                                                <Typography
                                                    fontSize={"12px"}
                                                    style={{
                                                        fontWeight: 600,
                                                        color: isSelected ? "#FB7E48" : "inherit",
                                                    }}
                                                >
                                                    {workspace.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item alignSelf={"center"}>
                                                {isSelected ? (
                                                    <CheckCircleIcon color="primary" style={{ fontSize: 16 }} />
                                                ) : (
                                                    <CompareArrowsIcon style={{ fontSize: 16 }} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                </SwipeableEdgeDrawer>
            )}
        </>
    );
};
const MobileNav = () => {
    const classes = useStyles();
    const isOnboarding = useSelector((state) => state.uiStates.isOnboarding);
    const userFeatures = useSelector((state) => state.users?.user?.subscription_meta_data.features_flags);
    const user = useSelector((state) => state.users);
    const [workspaceOpen, setWorkspaceOpen] = React.useState(false);
    const handleWorkspaceDrawer = (newOpen) => {
        setWorkspaceOpen(newOpen);
    };
    const [expanded, setExpanded] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [activeSection, setActiveSection] = React.useState(null);
    const dispatch = useDispatch();
    const auth = getAuth();
    const toggleDrawer = () => {
        setOpen(!open);
        dispatch({ type: TOGGLE_SIDENAV });
    };
    const handleBack = () => {
        setActiveSection(null);
    };
    const handleChange = (panel) => (event, isExpanded) => {
        setSelectedOption(panel);
        setExpanded(isExpanded ? panel : false);
    };
    function getInitialState() {
        var selectedOption = sessionStorage.getItem("SelectedOption") || "Account Settings";
        return { selectedOption: selectedOption };
    }
    const handleLogOut = async () => {
        await auth.signOut();
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        Cookies.remove("expiresIn");
        window.localStorage.clear();
        window.location.href = `${config.frontendUrl}login`;
    };

    // function to set the selected option in the session storage
    function setSelectedOption(option) {
        sessionStorage.setItem("SelectedOption", option);
    }
    const workspaces = useSelector((state) => state.users?.user.workspaces);
    const currentWorkspaceId = useSelector((state) => state.users?.user.current_workspace_id);
    let currentWorkspace = workspaces?.filter((workspace) => workspace.workspace_id === currentWorkspaceId);
    if (currentWorkspace?.length > 0) {
        currentWorkspace = currentWorkspace[0];
    }

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="top"
                open={open || activeSection !== null}
                onClose={activeSection ? handleBack : toggleDrawer}
                className={classes.drawer}
                transitionDuration={300}
            >
                <IconButton className={classes.closeButton} onClick={activeSection ? handleBack : toggleDrawer}>
                    <CloseIcon />
                </IconButton>
                {activeSection == null ? (
                    <List className={classes.navList}>
                        <ListItem
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "90%",
                                margin: "auto",
                                height: 48,
                                marginLeft: 8,
                                padding: "6px 16px 6px 2px",
                                userSelect: "none",
                                boxShadow: "0px 1px 10px 0px rgba(95, 95, 95, 0.16) inset",
                                backgroundColor: "rgba(236, 236, 236, 1)",
                                borderRadius: 10,
                            }}
                            onClick={() => {
                                setWorkspaceOpen(true);
                                toggleDrawer();
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ListItemIcon style={{ justifyContent: "center" }}>
                                    <img src={workspaceIcon} />
                                </ListItemIcon>
                                <Typography
                                    className={classes.subheading}
                                    style={{ fontFamily: "Montserrat", fontWeight: 600, color: "inherit" }}
                                >
                                    {currentWorkspace?.name}
                                </Typography>
                            </div>
                            <CompareArrowsIcon />
                        </ListItem>

                        {navData.map((data) => (
                            <OnboardingFlagHOC isOnboarding={isOnboarding} link={data.link}>
                                <ConditionalWrapper
                                    condition={data?.requiredPermission.length > 0}
                                    wrapper={(children) =>
                                        data?.account_level_permission ? (
                                            <CheckAccountPermissions requiredPermission={data?.requiredPermission}>
                                                {children}
                                            </CheckAccountPermissions>
                                        ) : (
                                            <CheckPermissions requiredPermission={data?.requiredPermission}>
                                                {children}
                                            </CheckPermissions>
                                        )
                                    }
                                >
                                    {data?.link ? (
                                        <Link to={data.link} color="primary" underline="none">
                                            <MainNavItem
                                                data={data}
                                                open={true}
                                                openItem={activeSection}
                                                setOpenItem={setActiveSection}
                                                hoveredItem={activeSection}
                                                setHoveredItem={setActiveSection}
                                                screenSize={screen.width}
                                            />
                                        </Link>
                                    ) : (
                                        <MainNavItem
                                            data={data}
                                            open={true}
                                            setHoveredItem={setActiveSection}
                                            setOpenItem={setActiveSection}
                                            hoveredItem={activeSection}
                                            openItem={activeSection}
                                            screenSize={screen.width}
                                        />
                                    )}
                                </ConditionalWrapper>
                            </OnboardingFlagHOC>
                        ))}
                        <ListItem
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                margin: "auto",
                                width: "97%",
                                height: 48,
                                marginBottom: 8,
                                marginLeft: 8,
                                padding: "6px 16px 6px 2px",
                                userSelect: "none",
                            }}
                            onClick={handleLogOut}
                        >
                            <ListItemIcon style={{ justifyContent: "center", opacity: 0.9, strokeOpacity: 0.9 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <Typography
                                className={classes.subheading}
                                style={{ fontFamily: "Montserrat", fontWeight: 500, color: "inherit" }}
                            >
                                Logout
                            </Typography>
                        </ListItem>
                    </List>
                ) : (
                    <List className={classes.navList}>
                        {NavMapper[activeSection]?.hasDropdown ? (
                            <>
                                {Object.keys(NavMapper[activeSection].data).map((key) => {
                                    if (
                                        (NavMapper[activeSection].data[key][0].role !== "none" &&
                                            NavMapper[activeSection].data[key][0].role !== user.user.account_role) ||
                                        (NavMapper[activeSection].data[key][0].selfServeOnly === true &&
                                            user.user.plan_name === "Unlimited")
                                    )
                                        return null;
                                    if (
                                        !checkForFeature(
                                            user.user.subscription_meta_data?.features_flags,
                                            NavMapper[activeSection].data[key][0].feature
                                        ) ||
                                        !checkForPermission(
                                            NavMapper[activeSection].data[key][0].account_level_permission
                                                ? user.user.account_permissions
                                                : user.user.permissions,
                                            NavMapper[activeSection].data[key][0].permission
                                        )
                                    )
                                        return null;
                                    return (
                                        <Dropdown
                                            keyName={key}
                                            items={NavMapper[activeSection].data[key].slice(1)}
                                            open={true}
                                            close={false}
                                            expanded={!expanded ? getInitialState().selectedOption : expanded}
                                            handleChange={handleChange}
                                            screenSize={screen.width}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                {NavMapper[activeSection]?.data.map((item) => (
                                    <MobileSideNavItem
                                        name={item.name}
                                        subtitle={item.subtitle}
                                        redirectLink={item.link}
                                        userFeatures={userFeatures}
                                        requiredFeatures={item.requiredFeatures}
                                        requiredPermission={item.requiredPermission}
                                        account_level_permission={item.account_level_permission}
                                    />
                                ))}
                            </>
                        )}
                    </List>
                )}
            </Drawer>
            <WorkspaceDrawer open={workspaceOpen} toggleDrawer={handleWorkspaceDrawer} />
        </div>
    );
};

export default MobileNav;
